






































import { Component } from 'vue-property-decorator'
import {
  DiscountsMixin,
  DiscountSource
} from '../../../loyalty/shared/mixins/discounts-mixin.component'

/**
 * @author Magdalena Pietruszewska <magdalena.pietruszewska@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({ name: 'BenefitsBox' })
export class BenefitsBox extends DiscountsMixin {
  public DiscountSource = DiscountSource
}

export default BenefitsBox
