import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

export enum DiscountSource {
  FriendsAndFamily = 'friendsAndFamily',
  Kameleon = 'kameleon',
  MilesAndMore = 'milesAndMore',
  Newsletter = 'newsletter'
}

@Component<DiscountsMixin>({
  created (): void {
    this.initDiscounts()
  }
})
export class DiscountsMixin extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  protected discounts: Record<DiscountSource, number> | null = null

  public getDiscount (source: string): number {
    if (!this.discounts || !this.isValidSource(source)) {
      return 0
    }

    return this.discounts[source]
  }

  private isValidSource (source: string): source is DiscountSource {
    return Object.values<string>(DiscountSource).includes(source)
  }

  private initDiscounts (): void {
    const settings = this.siteService.getProperty('discounts') as Record<string, number>

    this.discounts = Object.values(DiscountSource).reduce((acc, program) => {
      return {
        ...acc,
        [program]: settings[program] ?? 0
      }
    }, {} as Record<DiscountSource, number>)
  }
}
